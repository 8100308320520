import { Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import { Watch } from 'vue-property-decorator'
import BaseFunctions, { defaultClubManagerGroupId, defaultAdminBackendGroupId } from '@/components/Utility/Base'
import CommonFunctions, { defaultLicenseId, defaultJuniorLicenseId } from '@/components/Utility/Common'
import MembersDataService from '@/services/MembersDataService'
import UsersDataService from '@/services/UsersDataService'
import ByPostDataService from '@/services/ByPostDataService'
import { Products } from '@/services/ProductsDataService'
import { History } from '@/services/HistoryDataService'
import SysMember, { ClubRoleType, SharePermissionType } from '@/types/SysMember'
import SysUser from '@/types/SysUser'
import ByPost from '@/types/ByPost'
import SysCart from '@/types/SysCart'
import SysProduct from '@/types/SysProduct'
import AuthService from '@/services/AuthService'

type memberDataType = { memberFirstName: string; memberLastName: string; memberUserName: string; memberPassword: string; memberRepeatPassword: string; memberBirthDate: string; memberAddress: string; memberZipCode: string; memberCity: string; memberTelephone: string; memberEmail: string; memberLicense: number }
type dataReturnType = { memberData: memberDataType }

export default class newClubMember extends Vue {
  private clubManagerId = this.parseURL()
  private clubId = 0
  private userSlug = ''
  private todaysDate = new Date().toISOString().split('T')[0]
  private seasonHalfWayPoint = new Date(this.todaysDate).getFullYear() + '-07-01'
  private memberData: memberDataType = { memberFirstName: '', memberLastName: '', memberUserName: '', memberPassword: '', memberRepeatPassword: '', memberBirthDate: '', memberAddress: '', memberZipCode: '', memberCity: '', memberTelephone: '', memberEmail: '', memberLicense: 0 }
  private currentByPostId = 0
  private inhibitUpdateCityInfo = false
  private cityZipCodeInputFocus = 2 // Used to track which watch-tied input fields has the current input focus. Value 1 = city input field is in focus, value 2 = postal code input field is in focus
  private cityZipCode: ByPost[] = []
  private genderStringValue = 'Vælg køn'
  private genderStringOptions: string[] = ['Vælg her', 'Dame', 'Herre']
  private buyLicense = true
  private seniorLicenseProduct = {} as SysProduct
  private juniorLicenseProduct = {} as SysProduct
  private createNewMemberModal = false
  private newMember = {} as SysMember
  private userNameAvailable: boolean | null = null

  readonly name : string = 'newClubMember'
  $Message: any
  $Notification: any
  data (): dataReturnType {
    return {
      memberData: this.memberData
    }
  }

  @Watch('createNewMemberModal')
  onModalStatusChange (newVal : boolean) : void {
    if (!newVal) {
      this.newMember = {} as SysMember
      this.clearMemberData()
    }
  }

  public checkUserName () : void {
    let users: SysUser[] = []
    if (!(this.memberData.memberUserName.trim().length > 2)) {
      return this.$Message.warning({ text: 'Brugernavnet er for kort' })
    }
    UsersDataService.getAll('', null, `username=${this.memberData.memberUserName}`)
      .then((response) => {
        users = response.data

        if (users.length > 0) {
          this.userNameAvailable = false
          this.$Notification.warning({
            title: 'Brugernavnet er allerede taget'
          })
        } else {
          this.userNameAvailable = true
          this.$Notification.success({
            title: 'Brugernavnet er ledigt'
          })
        }

        console.log(this.userNameAvailable)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public convertUserNameToSlug (id: number, userName: string) : string {
    return id.toString() + '-' + CommonFunctions.slugify(userName)
  }

  public toCheckOut () : void {
    this.createNewMemberModal = false
    this.$router.push('/kassen')
  }

  public toMemberPortalMain () : void {
    this.createNewMemberModal = false
    this.$router.push({ name: 'MemberPortalMain', params: { userSlug: this.userSlug } })
  }

  public calculateAge (birthDate: string) : number {
    const ageDifMs = Date.parse(this.todaysDate) - new Date(birthDate).getTime()
    const ageDate = new Date(ageDifMs)

    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  public calcLicensePrice (birthDate: string, seniorPrice: number, juniorPrice: number) : number {
    const price = (this.calculateAge(birthDate) <= 17 ? juniorPrice : seniorPrice)
    if (Date.parse(this.todaysDate) > Date.parse(this.seasonHalfWayPoint)) {
      return price / 2
    }
    return price
  }

  public clearMemberData () : void {
    this.memberData = { memberFirstName: '', memberLastName: '', memberUserName: '', memberPassword: '', memberRepeatPassword: '', memberBirthDate: '', memberAddress: '', memberZipCode: '', memberCity: '', memberTelephone: '', memberEmail: '', memberLicense: 0 }
    this.genderStringValue = 'Vælg køn'
  }

  public newLicenseEndDate (currentEndDate: string | null) : string {
    const currentYear: number = new Date().getFullYear()

    if (currentEndDate === null || Date.parse(this.todaysDate) < Date.parse(currentYear.toString() + '-11-30')) {
      return (currentYear).toString() + '-12-31'
    }
    return (currentYear + 1).toString() + '-12-31'
  }

  public createMember () {
    let tempUserNames: SysUser[] = []
    let tempMemberLicenseNumbers: SysMember[] = []

    const getLicenseNumber = async () => {
      await MembersDataService.getAll('medlem_licens:desc', { slicemode: 0, start: 0, limit: 1, page: 0, pagesize: 0, totalcount: 0 })
        .then((response) => {
          tempMemberLicenseNumbers = response.data
          this.memberData.memberLicense = tempMemberLicenseNumbers[0].medlem_licens + 1
        })
        .catch((err) => {
          console.error(err)
        })
    }

    const checkLegalUserName = async (userName: string) => {
      await UsersDataService.getAll('', null, `username=${userName}`)
        .then((response) => {
          tempUserNames = response.data
        })
        .catch((err) => {
          console.error(err)
        })
    }

    if (!(this.memberData.memberFirstName.length > 1) || !(this.memberData.memberLastName.length > 1) || !(this.memberData.memberUserName.length > 1) || !(this.passwordTest(this.memberData.memberPassword)) || this.memberData.memberRepeatPassword !== this.memberData.memberPassword || !(this.memberData.memberBirthDate !== '') || !(this.testLegalEmail(this.memberData.memberEmail)) || !(this.genderStringValue !== 'Vælg køn')) {
      return this.$Message.warning({ text: 'Fejl: Ikke alle felter er udfyldt' })
    }

    const promises = []
    promises.push(getLicenseNumber())
    promises.push(checkLegalUserName(this.memberData.memberUserName))

    Promise.all(promises)
      .then(() => {
        if (this.memberData.memberLicense === 0) {
          return this.$Message.danger({ text: 'Der var et eller andet der gik galt, forsøg igen' })
        }
        if (tempUserNames.length > 0) {
          return this.$Message.danger({ text: 'Brugernavnet er allerede valgt, vælg et andet' })
        }

        const createUserData = {
          blocked: false,
          username: this.memberData.memberUserName,
          password: this.memberData.memberPassword,
          email: this.memberData.memberEmail,
          firstname: this.memberData.memberFirstName,
          lastname: this.memberData.memberLastName,
          phone: Number(this.memberData.memberTelephone),
          usrgroup: defaultAdminBackendGroupId,
          provider: 'local',
          role: '3',
          confirmed: true
        }

        UsersDataService.create(createUserData)
          .then((response) => {
            // console.log('User created: ' + response.statusText)
            const userId = response.data.id

            const createMemberData = {
              medlem_status: true,
              medlem_vejnummer: this.memberData.memberAddress,
              medlem_foedselsdag: this.memberData.memberBirthDate,
              medlem_begyndelse: new Date(),
              medlem_licens: this.memberData.memberLicense,
              medlem_paradart: false,
              bypost_id: this.currentByPostId,
              user_id: userId,
              koen_id: (this.genderStringValue === 'Herre' ? 2 : 1),
              klubber_id: this.clubId,
              medlem_klubrolle: ClubRoleType.Medlem,
              medlem_licens_slut: new Date(this.todaysDate),
              medlem_visoplysninger: SharePermissionType.Nej
            }

            MembersDataService.create(createMemberData)
              .then((response) => {
                // console.log('Member created: ' + response.statusText)
                this.newMember = response.data
                if (this.buyLicense) {
                  this.addLicenseToCart(Number(response.data.id))
                }
                const createHistoryEntry = {
                  medlemshistorik_handling: 'Oprettet d. ' + this.toDanishDateString(this.newMember.created_at),
                  medlem_id: Number(response.data.id)
                }

                History.HistoryDataService.create(createHistoryEntry)
                  .then((response) => {
                    // console.log('Create member history entry: ' + response.statusText)
                  })
                  .catch((err) => {
                    console.error(err)
                  })

                this.createNewMemberModal = true
              })
              .catch((err) => {
                console.error(err)
              })
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public toDanishDateString (dateString: string) : string {
    return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }).replaceAll('.', '/')
  }

  public addLicenseToCart (memberId: number) : void {
    if (!localStorage.getItem('cart')) {
      localStorage.setItem('cart', JSON.stringify([]))
    }
    const cartItems: SysCart[] = JSON.parse(localStorage.getItem('cart')!)

    let newItem = {} as SysCart
    const extraItemData: any = {
      type: 101,
      clubId: this.clubId,
      clubSaldoReduction: 0,
      memberId: memberId,
      memberUserName: this.memberData.memberUserName,
      // memberPassword: this.memberData.memberPassword,
      memberEmail: this.newMember.user_id.email,
      memberFirstName: this.memberData.memberFirstName,
      memberLastName: this.memberData.memberLastName,
      memberTelephone: (this.memberData.memberTelephone === undefined ? 0 : Number(this.memberData.memberTelephone)),
      memberAddress: this.memberData.memberAddress,
      cityZipId: this.currentByPostId,
      memberBirthDate: this.memberData.memberBirthDate,
      memberGenderValueId: (this.genderStringValue === 'Herre' ? 2 : 1),
      memberLicenseEnd: this.newLicenseEndDate(this.newMember.medlem_licens_slut),
      sharePermissionType: SharePermissionType.Nej
    }

    newItem = {
      placeInCart: Number(cartItems.length),
      productName: (this.calculateAge(this.newMember.medlem_foedselsdag) <= 17 ? this.juniorLicenseProduct.produkt_navn : this.seniorLicenseProduct.produkt_navn),
      productDescription: (this.calculateAge(this.newMember.medlem_foedselsdag) <= 17 ? 'Junior' : 'Senior') + 'licens til: ' + this.newMember.user_id.firstname + ' ' + this.newMember.user_id.lastname,
      price: this.calcLicensePrice(this.newMember.medlem_foedselsdag, this.seniorLicenseProduct.produkt_pris, this.juniorLicenseProduct.produkt_pris),
      dateInCart: this.todaysDate,
      expirationDate: null,
      playerRegistration: null,
      productId: this.calculateAge(this.newMember.medlem_foedselsdag) <= 17 ? defaultJuniorLicenseId : defaultLicenseId,
      quantity: 1,
      licenseRenewal: { memberID: Number(this.newMember.id), newLicenseEndDate: this.newLicenseEndDate(this.newMember.medlem_licens_slut) },
      clubLicenseRenewal: null,
      eventSignUp: null,
      teamRegistration: null,
      competitionFeeData: null,
      orderExtraData: (this.buyLicense ? extraItemData : null)
    }

    cartItems.push(newItem)
    localStorage.setItem('cart', JSON.stringify(cartItems))
    this.$Message.success({ text: 'Licensen er tilføjet til kurven' })
  }

  public parseURL () : number {
    let retVal = 0
    const route = useRoute()
    const managerId = route.params.userSlug.toString().split('-')[0]

    if (Number(managerId) !== undefined) {
      retVal = Number(managerId)
    }
    return retVal
  }

  public checkPermissions () {
    let manager: SysMember[] = []
    // Changed to use the userId from loacalStorage
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    if (userDataObject === null || userDataObject.id === 1) {
      console.log('ERROR fetching memberData')
      CommonFunctions.redirectLogin()
      return
    }
    MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)
      .then((response) => {
        manager = response.data

        if (manager.length !== 1) {
          this.$Message.danger({ text: 'Fejl: Et eller andet gik galt' })
          AuthService.logout()
          CommonFunctions.redirectLogin()
          return
        }
        if (manager[0].user_id.usrgroup !== defaultClubManagerGroupId || this.clubManagerId !== Number(manager[0].id)) {
          this.$Message.danger({ text: 'Du har ikke rettigheder til at være her' })
          return this.$router.push({ name: 'Login', path: 'medlemsportal/login' })
        }
        this.clubId = Number(manager[0].klubber_id.id)
        this.userSlug = this.convertUserNameToSlug(Number(manager[0].id), manager[0].user_id.username)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public retrieveDateTimeFromServer () : void {
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        // this.todaysDate = response.data.split('T')[0]
        this.todaysDate = new Date(response.data).toISOString().split('T')[0]
        this.seasonHalfWayPoint = new Date(this.todaysDate).getFullYear() + '-07-01'
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public passwordTest (passwordToBeTested: string) : boolean {
    return CommonFunctions.strongPasswordTest(passwordToBeTested)
  }

  public testLegalEmail (emailAddress: string) : boolean {
    return BaseFunctions.isEmail(emailAddress)
  }

  // Code for automatically fetching postal numbers, and matching it to city or vice versa.

  @Watch('memberData.zipCode')
  onZipCodeChange (zipCodeValue: string) {
    if (this.cityZipCodeInputFocus === 2 && this.inhibitUpdateCityInfo === false && !isNaN(Number(zipCodeValue))) {
      this.updateCityInputFieldDataFromPostalCodeInputFieldData(zipCodeValue)
    }
  }

  @Watch('memberData.city')
  onCityChange (cityValue: string) {
    if (this.cityZipCodeInputFocus === 1 && cityValue !== null) {
      this.updatePostalCodeInputFieldDataFromCityInputFieldData(cityValue)
    }
  }

  @Watch('cityZipCodeInputFocus')
  onCityZipCodeFocusChange (inputFocus: number) {
    if (inputFocus === 1) {
      if (!isNaN(Number(this.memberData.memberZipCode))) {
        this.updateCityInputFieldDataFromPostalCodeInputFieldData(this.memberData.memberZipCode)
      }
    } else if (inputFocus === 2) {
      if (this.memberData.memberCity !== null) {
        this.updatePostalCodeInputFieldDataFromCityInputFieldData(this.memberData.memberCity)
        this.updateCityInputFieldDataFromPostalCodeInputFieldData(this.memberData.memberZipCode)
      }
    }
  }

  public updatePostalCodeInputFieldDataFromCityInputFieldData (cityData: string) : void {
    if (cityData !== undefined && cityData !== null && cityData.length > 1) {
      ByPostDataService.findByCity(cityData, '', '1')
        .then((response) => {
          this.cityZipCode = response.data
          if (this.cityZipCode === undefined || this.cityZipCode === null || this.cityZipCode[0] === undefined) {
            this.currentByPostId = 0
          } else {
            this.inhibitUpdateCityInfo = true
            this.currentByPostId = (this.cityZipCode[0].id !== null ? Number(this.cityZipCode[0].id) : 0)
            // console.log('Current bypost Id : ' + this.currentByPostId)
            this.memberData.memberZipCode = (this.cityZipCode[0].id !== null ? (this.cityZipCode[0].bypost_postnummer.toString()) : '')
            this.inhibitUpdateCityInfo = false
          }
        })
        .catch((err) => {
          console.error(err)
          // console.log(err)
        })
    }
  }

  public updateCityInputFieldDataFromPostalCodeInputFieldData (postalCodeData: string) : void {
    if (postalCodeData !== undefined && postalCodeData !== null && Number(postalCodeData) > 799 && Number(postalCodeData) < 9999) {
      ByPostDataService.findByPostalnumber(Number(postalCodeData), '', '1')
        .then((response) => {
          this.cityZipCode = response.data
          if (this.cityZipCode === undefined || this.cityZipCode === null || this.cityZipCode[0] === undefined) {
            this.currentByPostId = 0
          } else {
            this.currentByPostId = (this.cityZipCode[0].id !== null ? Number(this.cityZipCode[0].id) : 0)
            // console.log('Current bypost Id : ' + this.currentByPostId)
            this.memberData.memberCity = (this.cityZipCode[0].id !== null ? (this.cityZipCode[0].bypost_by) : '')
          }
        })
        .catch((err) => {
          console.error(err)
          // console.log(err)
        })
    }
  }

  // Retrieve License products.
  public retrieveLicenseProducts () : void {
    const licenseProductParameter = 'id=' + defaultLicenseId.toString() + '&id=' + defaultJuniorLicenseId.toString()
    let tempLicense: SysProduct[] = []

    Products.ProductsDataService.getAll('', null, licenseProductParameter)
      .then((response) => {
        tempLicense = response.data
        const tempSeniorLicense = tempLicense.find(element => element.id === defaultLicenseId)
        const tempJuniorLicense = tempLicense.find(element => element.id === defaultJuniorLicenseId)
        if (tempSeniorLicense !== undefined && tempSeniorLicense !== null && tempJuniorLicense !== undefined && tempJuniorLicense !== null) {
          this.seniorLicenseProduct = tempSeniorLicense
          this.juniorLicenseProduct = tempJuniorLicense
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')
    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      this.checkPermissions()
      this.retrieveDateTimeFromServer()
      this.retrieveLicenseProducts()
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
